<template>
  <b-tab title="Output">
    <template v-if="apiResponse && apiResponse.output">
      <div
        v-for="(output, oIndex) in apiResponse.output"
        :key="`o-${oIndex}`"
        class="pl-2"
      >
        {{ output }}
      </div>
    </template>
    <p v-else class="pl-2 color-black">No data available.</p>
  </b-tab>
</template>
<script>
export default {
  name: 'Output',
  props: ['apiResponse']
}
</script>
